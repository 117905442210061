import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Support, {
  NonDesktopSupportContent,
} from '@components/layout/footer/support';
import { Refresh } from '@icons/Refresh';
import SupportServiceIcon from '@icons/SupportServiceIcon';
import { useBreakpoint } from '@shared/hooks/use-breakpoint';
import { Button } from '@ui/button';
import { Drawer, DrawerContent, DrawerTrigger } from '@ui/drawer';

import RiveAnimation from './RiveAnimation/RiveAnimation';

type BlockingErrorTypes = {
  actionText: string;
  description: string;
  onRefresh: () => void;
};

const BlockingError: React.FC<BlockingErrorTypes> = ({
  actionText,
  description,
  onRefresh,
}) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint.up('lg');

  return (
    <div className="relative flex h-[100svh] flex-col">
      <div className="py-[27px] pl-[24px] pr-[16px] xl:py-[35px] xl:pl-[40px] xl:pr-[16px]">
        <a href="/">
          <img
            src="/gcard-logotype.svg"
            alt="GCARD logotype"
            width="50"
            height="40"
          />
        </a>
      </div>
      <div className="flex h-full flex-col justify-between xl:mx-auto xl:max-w-[1440px] xl:flex-row xl:items-center xl:justify-center">
        <div className="flex h-[479px] w-fit justify-center self-center md:h-fit  md:w-[100%] lg:w-[50%]  xl:order-2 xl:p-0 xl:pr-[42px]">
          <RiveAnimation />
        </div>
        <div className="relative flex flex-col gap-y-[12px] self-end p-[24px] pt-[32px] md:p-[80px] md:pt-0 lg:self-center xl:w-[50%] xl:p-0 xl:pl-[80px]">
          <div>
            <p>{actionText}</p>
            <p className="py-[20px] text-[24px] font-normal leading-[120%] md:py-[24px] md:text-[48px] 2xl:text-[48px]">
              {description}
            </p>
          </div>
          <Button className="flex gap-x-[8px] self-start" onClick={onRefresh}>
            <Refresh />
            <span>Refresh Page</span>
          </Button>
          <div className="absolute bottom-[24px] right-[24px] h-12 w-12 md:bottom-[30px] md:right-[30px]">
            <Drawer>
              <DrawerTrigger asChild>
                <button className="grid h-12 w-12 place-content-center rounded-[50px] bg-neutral07 backdrop-blur-sm md:hidden">
                  <SupportServiceIcon className="h-4 w-4 [&>path]:fill-neutral03" />
                </button>
              </DrawerTrigger>
              <DrawerContent className="border-0 bg-neutral07 px-[28px] py-[32px]">
                <NonDesktopSupportContent />
              </DrawerContent>
            </Drawer>
            <Support
              position={isDesktop ? 'fixed' : 'absolute'}
              right="30px"
              bottom="30px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockingError;

export const ErrorPageWrapper: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const returnUrl = searchParams.get('returnUrl');

  const handleRefresh = () => {
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      window.location.reload();
    }
  };

  return (
    <BlockingError
      actionText="Updating Features ..."
      description="We are enhancing performance and adding new features for your convenience"
      onRefresh={handleRefresh}
    />
  );
};
