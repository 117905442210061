import { lazy, Suspense } from 'react';

import { LoadingDots } from '@components/loading-dots';

const DashboardPageLazy = lazy(() => import('./dashboard-page'));

const DashboardPageAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <DashboardPageLazy />
    </Suspense>
  );
};

export { DashboardPageAsync as DashboardPage };
