import { lazy, Suspense } from 'react';

import { LoadingDots } from '@components/loading-dots';

const FAQPageLazy = lazy(() => import('./faq-page'));

const FAQPageAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <FAQPageLazy />
    </Suspense>
  );
};

export { FAQPageAsync as FAQPage };
