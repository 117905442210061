import { lazy, Suspense } from 'react';

import { LoadingDots } from '@components/loading-dots';

const WithdrawalPageLazy = lazy(() => import('./withdrawal-page'));

const WithdrawalPageAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <WithdrawalPageLazy />
    </Suspense>
  );
};

export { WithdrawalPageAsync as WithdrawalPage };
