import { type ClassValue, clsx } from 'clsx';
import { setHours, setMilliseconds, setMinutes, setSeconds , formatDistanceToNow } from 'date-fns';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const setCurrentTime = (selectedDate: Date) => {
  const currentTime = new Date();
  let updatedDate = selectedDate;

  updatedDate = setHours(updatedDate, currentTime.getHours());
  updatedDate = setMinutes(updatedDate, currentTime.getMinutes() + 10);
  updatedDate = setSeconds(updatedDate, currentTime.getSeconds());
  updatedDate = setMilliseconds(updatedDate, currentTime.getMilliseconds());

  return updatedDate;
};

export function formatDateDistance(date: Date) {
  const options = {
    addSuffix: false,
    includeSeconds: true,
  };

  const distance = formatDistanceToNow(date, options);

  const [value, unit] = distance.split(' ');

  switch (unit) {
    case 'seconds':
    case 'second':
      return `${value}s`;
    case 'minutes':
    case 'minute':
      return `${value}m`;
    case 'hours':
    case 'hour':
      return `${value}h`;
    case 'days':
    case 'day':
      return `${value}d`;
    case 'weeks':
    case 'week':
      return `${value}w`;
    case 'months':
    case 'month':
      return `${value}mo`;
    case 'years':
    case 'year':
      return `${value}y`;
    default:
      return distance;
  }
}
