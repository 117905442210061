// TODO: Refactor. Make needed animation on Framer motion.
import React, { useEffect, useState } from 'react';

let ANIMATION_INTERVAL: ReturnType<typeof setInterval> | null;
const MAX_DOTS = 3;

export const LoadingDots = () => {
  const [dotsNumber, setDotsNumber] = useState(0);

  useEffect(() => {
    ANIMATION_INTERVAL = setInterval(() => {
      setDotsNumber((prev) => (prev >= MAX_DOTS ? 0 : prev + 1));
    }, 800);

    return () => {
      ANIMATION_INTERVAL && clearInterval(ANIMATION_INTERVAL);
    };
  }, []);

  const renderDots = () => {
    return Array(dotsNumber)
      .fill(1)
      .map((_, i) => <React.Fragment key={i}>.</React.Fragment>);
  };

  return <span>{renderDots()}</span>;
};
