import { lazy, Suspense } from 'react';

import { LoadingDots } from '@components/loading-dots';

const TopupPageLazy = lazy(() => import('./topup-page'));

const TopupPageAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <TopupPageLazy />
    </Suspense>
  );
};

export { TopupPageAsync as TopupPage };
