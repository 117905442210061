import { lazy, Suspense } from 'react';

import { LoadingDots } from '@components/loading-dots';

const ErrorPageWrapperLazy = lazy(async () => {
  const { ErrorPageWrapper } = await import('./blocking-error');
  return { default: ErrorPageWrapper };
});

const ErrorPageWrapperAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <ErrorPageWrapperLazy />
    </Suspense>
  );
};

export { ErrorPageWrapperAsync as ErrorPageWrapper };
