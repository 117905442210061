import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import router from '@app/router';
import { LoadingDots } from '@components/loading-dots';
import { Toaster } from '@ui/sonner';
import { SuperTokensWrapper } from 'supertokens-auth-react';

import ErrorBoundary from '../error-boundary';

const AppProvider = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingDots />}>
        <SuperTokensWrapper>
          <RouterProvider router={router} fallbackElement={<LoadingDots />} />
        </SuperTokensWrapper>
      </Suspense>
      <Toaster />
    </ErrorBoundary>
  );
};

export default AppProvider;
