import { lazy, Suspense } from 'react';

import { LoadingDots } from '@components/loading-dots';

const RequestCardPageLazy = lazy(() => import('./request-card-page'));

const RequestCardPageAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <RequestCardPageLazy />
    </Suspense>
  );
};

export { RequestCardPageAsync as RequestCardPage };
