import { lazy, Suspense } from 'react';

import { LoadingDots } from '@components/loading-dots';

const NotificationsPageLazy = lazy(() => import('./notifications-page'));

const NotificationsPageAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <NotificationsPageLazy />
    </Suspense>
  );
};

export { NotificationsPageAsync as NotificationsPage };
