import { lazy, Suspense } from 'react';

import { LoadingDots } from '@components/loading-dots';

const ConvertMoneyPageLazy = lazy(() => import('./convert-money-page'));

const ConvertMoneyPageAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <ConvertMoneyPageLazy />
    </Suspense>
  );
};

export { ConvertMoneyPageAsync as ConvertMoneyPage };
