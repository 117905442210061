import { lazy, Suspense } from 'react';

import { LoadingDots } from '@components/loading-dots';

const SavingsAccountsPageLazy = lazy(() => import('./savings-page'));
const SavingDetailsPageLazy = lazy(
  () => import('./details/saving-details-page'),
);

const SavingsAccountsPageAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <SavingsAccountsPageLazy />
    </Suspense>
  );
};

const SavingDetailsPageAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <SavingDetailsPageLazy />
    </Suspense>
  );
};

export {
  SavingsAccountsPageAsync as SavingsAccountsPage,
  SavingDetailsPageAsync as SavingDetailsPage,
};
