import { lazy, Suspense } from 'react';

import { LoadingDots } from '@components/loading-dots';

const TransactionsPageLazy = lazy(() => import('./transactions-page'));

const TransactionsPageAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <TransactionsPageLazy />
    </Suspense>
  );
};

export { TransactionsPageAsync as TransactionsPage };
