import { lazy, Suspense } from 'react';

import { LoadingDots } from '@components/loading-dots';

const CardDetailsPageLazy = lazy(() => import('./details/card-details-page'));

const CardDetailsPageAsync = () => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <CardDetailsPageLazy />
    </Suspense>
  );
};

export { CardDetailsPageAsync as CardDetailsPage };
